const territoryCodes: { [id: string]: string } = {
  CZ010: "Hlavní město Praha",
  CZ020: "Středočeský kraj",
  CZ031: "Jihočeský kraj",
  CZ032: "Plzeňský kraj",
  CZ041: "Karlovarský kraj",
  CZ042: "Ústecký kraj",
  CZ051: "Liberecký kraj",
  CZ052: "Královéhradecký kraj",
  CZ053: "Pardubický kraj",
  CZ063: "Kraj Vysočina",
  CZ064: "Jihomoravský kraj",
  CZ071: "Olomoucký kraj",
  CZ072: "Zlínský kraj",
  CZ080: "Moravskoslezský kraj",
};

export const getTerritoryName = (cznutsCode?: string): string => {
  if (!cznutsCode) {
    return "Ostatní";
  }
  return territoryCodes[cznutsCode.toUpperCase()];
};
