import { useMemo, useState } from "react";
import { SelectOption } from "../../types";
import { CourseData } from "./useCategoryQuery";

import {} from "lodash";

export const useCategoryFilter = (data: CourseData[]) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>("");

  const filteredData = useMemo(
    () => (selectedFilter !== "" ? data.filter((row) => row.territory === selectedFilter) : data),
    [selectedFilter]
  );

  const onFilterChange = (option: SelectOption | null) => {
    if (option && option.value !== selectedFilter) {
      setSelectedFilter(option.value);
    }
  };

  return {
    data: filteredData,
    onFilterChange,
  };
};
