import React from "react";
import Collapse from "@kunukn/react-collapse";
import { CourseData } from "./useCategoryQuery";
import Arrow from "../../img/arrow.svg";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

interface CourseCardProps {
  course: CourseData;
}

interface CourseAttribute {
  attrib: string;
  value?: string;
  className?: string;
}

export const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const toggle = () => {
    setOpen(!isOpen);
  };

  const sidebarData: CourseAttribute[] = [
    //{ attrib: "Termín přihlášek", value: course.submissionDate?.toLocaleDateString() },
    //{ attrib: "Přijímačky", value: course.examsDate?.toLocaleDateString() },
    // { attrib: "Design", value: course.designScore + "/10", className: "violet" },
    // { attrib: "Výtvarnost", value: course.creativityScore + "/10", className: "green" },
    // { attrib: "Techničnost", value: course.techSkillScore + "/10", className: "yellow" },
  ];

  return (
    <div className={"course-card"}>
      <button onClick={toggle}>
        <div className="course-header">
          <div className="course-names">
            <h2>{course.schoolName}</h2>
            <h1>{course.courseName}</h1>
          </div>
          <div className="course-city-arrow">
            <span>{course.city}</span>
            <Arrow className={"course-arrow" + (isOpen ? " up" : "")} />
          </div>
        </div>
      </button>
      <Collapse
        isOpen={isOpen}
        transition={"height 280ms cubic-bezier(0.4, 0, 0.2, 1)"}
        className="course-collapse"
      >
        <div className="course-layout">
          <div className="course-sidebar">
            {/* {sidebarData.map((sd, i) => (
              <div key={i}>
                {sd.value && (
                  <div className={"course-attribute" + (sd.className ? " " + sd.className : "")}>
                    <h4>{sd.attrib}</h4>
                    <div>{sd.value}</div>
                  </div>
                )}
                {sd.attrib === "Přijímačky" && (
                  <div className="info-text" key={i + 20}>
                    Data jsou pouze orientační, aktuální data najdete na stránkách školy
                  </div>
                )}
              </div>
            ))} */}
            <div>
              {course.logo && <Img fluid={course.logo} alt={course.courseName + " - " + course.schoolName} />}
            </div>
          </div>
          <div className="course-content">
            <h3>Profil oboru</h3>
            <div>
              <ReactMarkdown>{course.description ?? ""}</ReactMarkdown>
            </div>
            <h3>Profil absolventa </h3>
            <div>
              <ReactMarkdown>{course.profile ?? ""}</ReactMarkdown>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
