import React, { useMemo } from "react";
import { FluidObject } from "gatsby-image";
import { LandingCategory } from "../Landing";
import { Page } from "../layout/Page";
import { useCategoryQuery } from "./useCategoryQuery";
import { CourseCard } from "./CourseCard";

import ReactSelect, { defaultTheme } from "react-select";
import { useCategoryFilter } from "./useCategoryFilter";
import { getTerritoryName } from "../../lib/territoryUtil";
import { SelectOption } from "../../types";

interface CategoryPageProps {
  title: string;
  categoryImage: FluidObject;
  collectionKey: string;
}

const ids = [
  "CZ010",
  "CZ020",
  "CZ031",
  "CZ032",
  "CZ041",
  "CZ042",
  "CZ051",
  "CZ052",
  "CZ053",
  "CZ063",
  "CZ064",
  "CZ071",
  "CZ072",
  "CZ080",
];

export const CategoryPage: React.FC<CategoryPageProps> = ({ collectionKey, categoryImage, title }) => {
  const courses = useCategoryQuery(collectionKey);
  const { data, onFilterChange } = useCategoryFilter(courses);

  const filterOptions: SelectOption[] = useMemo(() => {
    const defaultOption = { label: "Všechny kraje", value: "" };
    const otherOption = { label: "Ostatní", value: null };
    const options = ids.map((id) => ({ label: getTerritoryName(id), value: id }));
    return [defaultOption, ...options, otherOption];
  }, []);

  const styles = {
    control: (base, props) => ({
      ...base,
      borderColor: defaultTheme.colors["primary75"],
      borderWidth: 2,
      borderRadius: 0,
      "&:active": {
        borderWidth: 2,
      },
    }),
  };

  return (
    <div>
      <LandingCategory name={title} image={categoryImage} />
      <Page>
        <div className="course-filter">
          <ReactSelect
            options={filterOptions}
            defaultValue={filterOptions[0]}
            onChange={onFilterChange}
            styles={styles}
          />
        </div>
        <div className="courses-list">
          {data.map((course) => (
            <CourseCard course={course} key={course.courseName} />
          ))}
        </div>
      </Page>
    </div>
  );
};
