import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface CourseData {
  courseName: string;
  schoolName?: string;
  description?: string;
  profile?: string;
  webUrl?: string;
  designScore: number;
  creativityScore: number;
  techSkillScore: number;
  submissionDate?: Date;
  examsDate?: Date;
  address?: string;
  city?: string;
  territory?: string;
  email?: string;
  phone?: string;
  logo?: FluidObject;
}

export const useCategoryQuery = (collectionKey: string): CourseData[] => {
  const queryDataRaw = useStaticQuery(graphql`
    query CategoryQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              type
              courseName
              schoolName
              description
              profile
              webUrl
              designScore
              creativityScore
              techSkillScore
              submissionDate
              examsDate
              address
              city
              territory
              email
              phone
              logo {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 40) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const filteredQuery = queryDataRaw.allMarkdownRemark.edges.filter(
    (edge) => edge.node.frontmatter.type === collectionKey
  );

  const result: CourseData[] = filteredQuery.map((edge) => {
    const {
      courseName,
      schoolName,
      description,
      profile,
      webUrl,
      designScore,
      creativityScore,
      techSkillScore,
      submissionDate,
      examsDate,
      address,
      city,
      territory,
      email,
      phone,
      logo,
    } = edge.node.frontmatter;

    return {
      courseName,
      schoolName,
      description,
      profile,
      webUrl,
      designScore,
      creativityScore,
      techSkillScore,
      submissionDate: new Date(submissionDate),
      examsDate: new Date(examsDate),
      address,
      city,
      territory,
      email,
      phone,
      logo: logo?.childImageSharp?.fluid,
    };
  });
  return result;
};
